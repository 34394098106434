import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  table: {
    minWidth: 700,
  }
});

class BiologicalIndicators extends React.Component {

    constructor(props) {
        super(props);

        // this.state = {
        //     selectedDate: new Date(),
        // }

    }

    
    printElem = () => {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById("contentContainer").outerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };

    render() {
        const {classes} = this.props;
        return (
            this.props.content ?
            <Paper className={classes.root}>
            <Button color="primary" style={{float: "right"}} onClick={this.printElem}>Print</Button>
            {/* <Typography style={{textAlign: 'center'}}>
            <DatePicker
                        label="Pick Date"
                        views={["year", "month"]}
                        minDate={new Date("2016-01-01")}
                        value={this.state.selectedDate}
                        onChange={this.handleDateChange}
                        />
            </Typography> */}
            <Table className={classes.table}  id={"contentContainer"} >
            <TableHead>
                <TableRow>
                    <TableCell align="right">Start Time</TableCell>
                    <TableCell align="right">Verify Time</TableCell>
                    <TableCell align="right">Lot #</TableCell>
                    <TableCell align="right">Cycle</TableCell>
                    <TableCell align="right">Control Vial</TableCell>
                    <TableCell align="right">Test Vial</TableCell>
                    <TableCell align="right">Start User</TableCell>
                    <TableCell align="right">Verify Use</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.props.content.map(row => (
                    <TableRow>
                    <TableCell align="right">{new Date(Object.values(row)[1]*1000).toLocaleString("en-US",{timeZone: 'GMT'})}</TableCell>
                    <TableCell align="right">{new Date(Object.values(row)[2]*1000).toLocaleString("en-US",{timeZone: 'GMT'})}</TableCell>
                    <TableCell align="right">{Object.values(row)[0]}</TableCell>
                    <TableCell align="right">{Object.values(row)[3]}</TableCell>
                    <TableCell align="right">{Object.values(row)[4] !== "Pass" ? <RemoveCircleOutlineIcon style={{height: "1em"}} color="error" /> : <CheckCircleOutlineIcon style={{height: "1em"}} color="primary" />}</TableCell>
                    <TableCell align="right">{Object.values(row)[5] !== "Pass" ? <RemoveCircleOutlineIcon style={{height: "1em"}} color="error" /> : <CheckCircleOutlineIcon style={{height: "1em"}} color="primary" />}</TableCell>
                    <TableCell align="right">{Object.values(row)[6]}</TableCell>
                    <TableCell align="right">{Object.values(row)[7]}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </Paper>
            : <h3 style={{textAlign: 'center'}}>No results found.</h3>
        );
    }
}

BiologicalIndicators.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(BiologicalIndicators);