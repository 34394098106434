import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from "./Loading";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CycleDetails from './CycleDetails';
import Paper from '@material-ui/core/Paper';
import { DatePicker } from "material-ui-pickers";
import axios from 'axios';
import ls from 'local-storage';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const mockContents = `
----------------------------
Contents of cycle # 2440
----------------------------
786247  Resto kit
786248  Exam kit
786249\tHygiene
20000 Abe Ret

----------------------------
End of contents listing
----------------------------
`

const mockDataLogger = `
Midmark M11 - v1.0.4
________________________
Begin cycle # 2440
------------------------
  Date       Time   
Jul 5 2019  13:48
------------------------
Local Standard Time
------------------------
Total cycles 2440

Sterilizer ID
____________________
Operator
__ / __ / ____  __ : __
mm / dd / yyyy  hh : mm

BEGIN WRAPPED CYCLE

Temp:  270 Degrees F
Time:    5 Minutes
Dry:   30 Minutes

FILLING CHAMBER

HEATING

mm:ss  Degrees   PSI
0:00  156.3 F   0.2
0:30  157.3 F   0.3
1:00  156.6 F   0.8
1:30  156.4 F   1.4
2:00  156.3 F   2.1
2:30  156.7 F   2.8
3:00  157.2 F   3.6
3:30  158.5 F   4.6
4:00  165.1 F   5.7
4:30  171.7 F   6.8
5:00  178.2 F   7.9
5:30  184.8 F   9.1
6:00  190.5 F  10.2
6:30  195.9 F  11.3
7:00  201.8 F  12.6
7:30  205.0 F   9.8
8:00  211.5 F   5.7
8:30  213.9 F   5.4
9:00  218.1 F   6.8
9:30  222.1 F   8.1
10:00  225.2 F   9.4
10:30  229.1 F  10.7
11:00  232.5 F  12.1
11:30  235.7 F  13.5
12:00  232.4 F   9.7
12:30  229.9 F   8.2
13:00  233.7 F   9.8
13:30  237.1 F  11.3
14:00  240.3 F  12.8
14:30  239.3 F  11.5
15:00  235.1 F   9.3
15:30  236.4 F  10.3
16:00  240.1 F  12.0
16:30  243.5 F  13.7
17:00  247.0 F  15.4
17:30  250.3 F  17.2
18:00  253.4 F  18.9
18:30  256.5 F  20.7
19:00  259.5 F  22.6
19:30  262.5 F  24.4
20:00  265.4 F  26.3
20:30  268.3 F  28.3
21:00  271.2 F  30.3
21:30  272.2 F  30.6

STERILIZING

mm:ss  Degrees   PSI
0:00  271.7 F  30.5
0:30  272.1 F  30.6
1:00  272.5 F  30.9
1:30  272.8 F  31.3
2:00  272.3 F  31.1
2:30  271.8 F  30.5
3:00  272.3 F  30.8
3:30  272.4 F  31.3
4:00  272.3 F  31.1
4:30  271.8 F  30.5
5:00  272.2 F  30.5
Min  271.7 F  30.4
Max  272.9 F  31.4
VENTING CHAMBER

DRYING

mm:ss

0:00 Drying Start
5:00
10:00
15:00
20:00
25:00
30:00 Drying Complete

FILLING:        1:02
HEATING:       21:38
STERILIZING:    5:00
VENTING:        3:27
DRYING:        30:00
TOTAL CYCLE:01:01:07

CYCLE COMPLETE
------------------------
Digital Key# 
1A3171143026
------------------------
Visual verification
Class 1 CI Pass
Class 4 CI Pass
Class 5 CI Pass
Time, Min Temp & Pressure Pass
Results verified by test
------------------------
`

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  cycleList: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  cycleDetail: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  }
});

const filterValues = (array, text) => {
	return array.filter(function(item) {
  	return item.indexOf(text) != -1;
	});
}

class CycleListing extends React.Component {

  state = {
    steriloggerUri: "",
    selectedDate: new Date(),
    cycles: [],
    responseStatus: "",
    expanded: null,
    open: false,
    cycleDetailUri: null,
    cycleDetail: null,
    cycleContents: null,
    loadingCycles: false
  };

  handleChange = (panel, steriloggerUri) => (event, expanded) => {
    let year = this.state.selectedDate.getFullYear();
    let month = months[this.state.selectedDate.getMonth()].substring(0, 3).toUpperCase();
    this.getCyclesForDate(steriloggerUri, year, month);
    this.setState({
      expanded: expanded ? panel : false,
      steriloggerUri: steriloggerUri 
    });
  };

  handleDateChange = (date) => {
    let year = date.getFullYear();
    let month = months[date.getMonth()].substring(0, 3).substring(0, 3).toUpperCase();
    this.getCyclesForDate(this.state.steriloggerUri, year, month);
    this.setState({
        selectedDate: date
    })
  }

  getCyclesForDate(steriloggerUri, year, month) {
    this.setState({
      loadingCycles: true
    })
    // mock!!!
    if (this.props.mock) {
        this.setState({
          loadingCycles: false,
          cycles: ["C-2437.txt","C-2438.txt","C-2439.txt","C-2440.txt","C-2441.txt","C-2442.txt","C-2443.txt","C-2444.txt"]
        });
    } else {
      const uri = steriloggerUri + "/" + year + "/" + month;      
      axios.get(uri
      ).then(response => {
        response = response.data.split(",").slice(0, -1);
          this.setState({
              cycles: filterValues(response, "C-"),
              responseStatus: response.status
          }) 
      }).catch(error => {
          console.log(error.response);
          this.setState({
              cycles: [],
              responseStatus: error.response.status
          });
        }).then(() => {
          this.setState({
            loadingCycles: false
          })
        });
    }
  }

  handleClickOpen = (cycle) => (e) => {
    
    // mock!!
    if (this.props.mock) {
      this.setState({ 
        cycleDetail: mockDataLogger,
        cycleDetailUri: "http://google.com",
        cycleContents: mockContents,
        open: true})
    } else {
      let year = this.state.selectedDate.getFullYear();
      let month = months[this.state.selectedDate.getMonth()].substring(0, 3).toUpperCase();
      let cycleDetailUri = this.state.steriloggerUri + "/" + year + "/" + month;
      axios.get(cycleDetailUri + "/" + cycle
        ).then(response => {
            let cycleDetail = response.data;
            this.setState({ 
              cycleDetail,
              cycleName: cycle,
              open: true});
        }).catch(error => {});

      axios.get(cycleDetailUri + "/L" + cycle.substring(1)
        ).then(response => {
            let cycleContents = response.data;
            this.setState({ 
              cycleContents
            });
        }).catch(error => {});
    }
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false, cycleDetail: null });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
      {this.props.isLoading ? 
      (
      <Loading />
    ) : (
      (this.props.mock ? !Object.entries(this.props.steriLoggers).length === 0 : Object.entries(this.props.steriLoggers).length === 0) ? 
        (
          <h3 style={{textAlign: 'center'}}>No Steri-Scanners found.</h3>
        ) : (
          (Object.keys(this.props.steriLoggers)).map((value, index) => (
            <ExpansionPanel expanded={expanded === 'panel' + index} onChange={this.handleChange('panel' + index, this.props.steriLoggers[value])}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Steri-Scan {ls.get("sterinames") && ls.get("sterinames")[value] && value.split("/")[0] + "/" + ls.get("sterinames")[value] || value}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                  {this.state.responseStatus === 503 ? (
                  <h3 style={{textAlign: 'center', width: "100%"}}>Sterilizer currently in use.</h3>
                  ) : (
                  <Paper className={classes.cycleList}>
                  <Table className={classes.table}>
                    <TableHead
                        style={{textAlign: "center", background: "whitesmoke"}}>
                      <Typography color="inherit" style={{
                          textAlign: "center",
                          display: "inline-flex",
                          padding: "5px",
                          fontSize: "20px"
                      }}>
                        <div className="picker">
                          <DatePicker
                              views={["year", "month"]}
                              minDate={new Date("2016-01-01")}
                              value={this.state.selectedDate}
                              onChange={this.handleDateChange}
                          />
                        </div>
                      </Typography>
                    </TableHead>
                    <TableBody>
                      {this.state.loadingCycles ? (
                        <Loading />
                      ) : (
                        Object.entries(this.state.cycles).length === 0 ?
                            (
                                <h3 style={{textAlign: 'center'}}>No cycles to display.</h3>
                            ) : (
                                <List className={classes.cycleDetail}
                                      subheader={<li />}>
                                  <li className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        {this.state.cycles.map(item => (
                                            <ListItem style={{
                                                display: "inline-flex",
                                                width: "auto"
                                            }} key={`item-${item}`} button
                                                      onClick={this.handleClickOpen(item)}>
                                              <Avatar>
                                                <AttachFileIcon />
                                              </Avatar>
                                              <ListItemText primary={item}/>
                                            </ListItem>
                                        ))}
                                    </ul>
                                  </li>
                                  <CycleDetails
                                      open={this.state.open}
                                      cycledetail={this.state.cycleDetail}
                                      cyclecontents={this.state.cycleContents}
                                      cyclename={this.state.cycleName}
                                      fullScreen
                                      onClose={this.handleClose}

                                  />
                                </List>
                            )
                      )}
                    </TableBody>
                  </Table>
                  </Paper>
                  )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      ))
      ))}

      </div>
    );
  }
}

CycleListing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CycleListing);