import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
const styles = theme => ({
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
    },
    grid: {
      flexGrow: 1,
      padding: theme.spacing.unit * 2,
    },
    paper: {
      boxShadow: "none",
      padding: theme.spacing.unit * 2,
      textAlign: 'center',
    },
  });

let id = 0;
function createData(barcode, content, user ) {
  id += 1;
  return { id, barcode, content, user };
}

const rows = [
  createData(89037, "Hygiene", "John"),
  createData(89038, "Resto", "John"),
  createData(89039, "Surgical", "John"),
  createData(89040, "Endo", "John"),
  createData(89041, "CrownBdg", "John"),
  createData(89042, "Hand Piece", "John"),
];

class CycleDetails extends React.Component {

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  printElem = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<pre>');
    mywindow.document.write(this.props.cycledetail);
    mywindow.document.write(this.props.cyclecontents);
    mywindow.document.write('</pre>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  };

  saveElem = () => {
    var link = document.createElement('a');
    link.setAttribute('download', this.props.cyclename);
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.props.cycledetail) + encodeURIComponent(this.props.cyclecontents));
    link.click(); 
  }

  render() {
    const { classes, ...other } = this.props;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
      <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
              Cycle Details
              </Typography>
              <Button color="inherit" onClick={this.saveElem}>Save</Button>
              <Button color="inherit" onClick={this.printElem}>Print</Button>
            </Toolbar>
          </AppBar>
          <div className={classes.grid}>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <pre>
                  {this.props.cycledetail}
                </pre>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <pre>
                  {this.props.cyclecontents}
                </pre>
              </Paper>
            </Grid>
            {/* <Grid item xs={8}>
              <Paper className={classes.paper}>
              <Typography variant="h5" style={{textAlign: 'left'}}>
                Contents
              </Typography>
              <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">Barcode #</TableCell>
                        <TableCell align="right">Content</TableCell>
                        <TableCell align="right">User</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.id}>
                        <TableCell align="right">{row.barcode}</TableCell>
                        <TableCell align="right">{row.content}</TableCell>
                        <TableCell align="right">{row.user}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>                    
              </Paper>
            </Grid> */}
          </Grid>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CycleDetails);