import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RedSnackbar from './RedSnackbar';
import { DEFAULT_PASSWORD } from './Dashboard';
import ls from 'local-storage';
import axios from 'axios';

const styles = theme => ({
    textField: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
        marginTop: '0px',
        width: '300px'
    },
    button: {
        display: 'flex',
        margin: '10px'
    }
});

class Password extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mock: this.props.mock,
            password: "",
            showLoginFailure: false,
            authenticated: false,
        };
    }
    
    forgot = () => {
        this.props.history.push('/forgot');
    }


    logIn = () => {
        if (this.state.mock) {
            if (this.state.password === ls.get("password")) {
                this.props.handler(this.state.password);
                this.setState({
                    showLoginFailure: false
                })                
            } else {
                this.setState({
                    showLoginFailure: true
                })
            }            
        } else {   
            // this function iterates over known steriscanners looking for one
            // that responds with 200.  if one does, updates email/pass for those
            // that respond with 401.
            let failedUris = [];
            let successUri = "";
            let callbackCounter = 0;         
            for (let steriLoggerUri of Object.values(this.props.steriLoggers)) {
                axios.get(steriLoggerUri + "/password/" + this.state.password
                ).then(response => {
                    if (response.status === 200) {
                        successUri = steriLoggerUri;
                    }
                }).catch(error => {
                    if (error.response.status === 401) {
                        failedUris.push(steriLoggerUri);
                    }

                }).then(() => {
                    callbackCounter++;
                    if (callbackCounter === Object.values(this.props.steriLoggers).length) {
                        if (failedUris.length > 0) {
                            for (let steriLoggerUri of failedUris) {
                                let updateableUris = [];
                                let callbackCounter2 = 0;
                                axios.get(steriLoggerUri + "/password/" + DEFAULT_PASSWORD
                                ).then(response => {
                                    if (response.status === 200) {
                                        updateableUris.push(steriLoggerUri);
                                    }
                                }).catch(error => {
                                    console.log(error);
                                }).then(() => {
                                    callbackCounter2++;
                                    if (callbackCounter2 === failedUris.length) {
                                        console.log(updateableUris);
                                        let email = "";
                                        if (successUri) {
                                            axios.get(successUri + "/email"
                                            ).then(response => {
                                                if (response.status === 200) {
                                                    email = response.data;
                                                }

                                                for (let steriLoggerUri of failedUris) {
                                                    axios.get(steriLoggerUri + "/updatepw/" + DEFAULT_PASSWORD + "/" + this.state.password
                                                    ).then(response => {
                                                        if (response.status === 200) {
                                                            axios.get(steriLoggerUri + "/addemail/" + this.state.password + "/" + email
                                                            ).then().catch(error => {
                                                                console.log(error);
                                                            });
                                                        }
                                                    }).catch(error => {
                                                        console.log(error);
                                                    });

                                                }
                                            }).catch(error => {
                                                console.log(error);
                                            });
                                        }
                                    }
                                });
                            }
                        }

                        if (successUri) {
                            this.props.handler(this.state.password);
                            this.setState({
                                showLoginFailure: false
                            })
                        } else {
                            this.setState({
                                showLoginFailure: true
                            })
                        }

                    }
                });
            }
        }
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    handleClose = () => {
        this.setState({ showLoginFailure: false });
    };

    render() {
        const {classes} = this.props;
        // console.log(this.state.mock);

        return (
            <div>
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Please enter your password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange}
                    margin="normal"
                />
                <Button variant="contained" className={classes.button} onClick={this.logIn}>
                    Login
                </Button>

                <Button variant="contained" className={classes.button} onClick={this.forgot}>
                    Forgot Password
                </Button>

                <RedSnackbar
                    open={this.state.showLoginFailure}
                    message="Password incorrect."
                    onClose={this.handleClose}
                />

            </div>
        );
    }
}

Password.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Password);