import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Loading from "./Loading";
import { EditingState } from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import RedSnackbar from "./RedSnackbar";
import axios from 'axios';
import ls from 'local-storage';

const getRowId = row => row.id;

const columnTypes = {
    "users": [
        { name: 'User', title: 'Name' },
        { name: 'PassWord', title: 'PIN' }
    ],
    "contents": [
        { name: 'Item', title: 'Item' },
    ]
};

const endpoint = {
    "users": "users",
    "contents": "button"
};

class UserManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            mock: this.props.mock,
            columns: columnTypes[this.props.type],
            open: false,
            message: "",
            responseStatus: "",
        };

        this.commitChanges = this.commitChanges.bind(this);
    }

    handleClick = state => () => {
        this.setState({ open: true, ...state });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    commitChanges({ added, changed, deleted }) {
        let rows = this.props.content;
        let currentRows = [];
        let payload = [];
        this.setState({
            loading: true
        });

        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            currentRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }

        if (changed) {
            currentRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        }

        if (deleted) {
            const deletedSet = new Set(deleted);
            currentRows = rows.filter(row => !deletedSet.has(row.id));
        }

        if (this.props.type === "users") {
            for (let row of currentRows) {
                // validate length of username/password.
                if (row.PassWord) {
                    if (row.PassWord.toString().length !== 3) {
                        // make it so the user can see this.
                        this.setState({
                            message: "PIN must contain exactly three numbers.",
                            open: true,
                            loading: false
                        });
                        return;
                    }

                    let existingPasswords = currentRows.map(user => user.PassWord);
                    console.log(existingPasswords);

                    if (existingPasswords.filter(password => password == row.PassWord).length > 1) {
                        this.setState({
                            message: "PIN already in use, please choose another.",
                            open: true,
                            loading: false
                        });
                        return;
                    }

                    for (let character of row.PassWord.toString()) {
                        if (character > 3 || character < 1) {
                            this.setState({
                                message: "PIN can only contains digits between 1 and 3.",
                                open: true,
                                loading: false
                            });
                            return;
                        }
                    }

                } else {
                    this.setState({
                        message: "PIN cannot be empty.",
                        open: true,
                        loading: false
                    });
                    return;
                }

                if (!row.User || row.User.length > 10) {
                    // make it so the user can see this.    
                    this.setState({
                        message: "Usernames must contain less than 10 characters.",
                        open: true,
                        loading: false
                    });
                    return;
                }

                payload.push(row.User, row.PassWord);
                if (this.state.mock) {
                    ls.set("users", payload);
                }
            }
        } else if (this.props.type === "contents") {
            for (let row of currentRows) {
                // validate length of content.
                if (!row.Item || row.Item.length > 10) {
                    // make it so the user can see this.
                    this.setState({
                        message: "Usernames must contain less than 10 characters.",
                        open: true,
                        loading: false
                    });
                    return;
                }

                payload.push(row.Item);
                if (this.state.mock) {
                    ls.set("contents", payload);
                }
            }
        }

        // append none,none to the end of the array until we have 9 pairs of username/password.
        // or append none until we have 18 total contents.

        for (let i = payload.length + 1; i <= 18; i++) {
            payload.push("none");
        }

        if (!this.state.mock) {
            axios.get(this.props.steriLoggerUri + "/" + endpoint[this.props.type] + "/" + this.props.password + "," + payload
            ).then(response => {
                if (response.status === 200) {
                    rows = currentRows;

                    this.props.handler(rows);
                    this.setState({
                        loading: false
                    });
                }
            }).catch(error => {
                console.log(error);

                this.setState({
                    loading: false
                });
            });
        } 
        else {
            rows = currentRows;
            this.props.handler(rows);
            this.setState({
                loading: false
            });
        }
    }

    render() {

        return (
            <div style={{ width: "100%" }}>
                <Paper style={{ overflowX: 'auto' }}>
                    {this.state.loading && <Loading />}

                    <Grid
                        rows={this.props.content}
                        columns={this.state.columns}
                        getRowId={getRowId}
                    >
                        <EditingState
                            onCommitChanges={this.commitChanges}
                        />
                        <Table />
                        <TableHeaderRow />
                        <TableEditRow />
                        <TableEditColumn
                            showAddCommand={this.props.type !== 'contents'}
                            showEditCommand
                            showDeleteCommand={this.props.type === 'users'}
                        />
                    </Grid>
                </Paper>

                <RedSnackbar
                    open={this.state.open}
                    message={this.state.message}
                    onClose={this.handleClose}
                />
            </div>
        );
    }
}

export default UserManagement;