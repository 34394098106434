import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { withRouter } from "react-router-dom";
import RedSnackbar from "./RedSnackbar";
import axios from 'axios';

const LAMBDA_URL = "https://kyho30a55h.execute-api.us-east-1.amazonaws.com/password-reset-lambda-dev-hello";

const styles = theme => ({
    root: {
        width: '100%'
    },
    settings: {
        overflowX: 'auto',
        padding: '20px'
    },
    textField: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
        marginTop: '0px',
        width: '300px',
        display: 'flex'
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 1
    },
    button: {
        display: 'flex',
        margin: '10px'
    }
});
class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        let steriNumber = this.props.steriLoggers && Object.keys(this.props.steriLoggers).length > 0 ? Object.keys(this.props.steriLoggers)[0] : "";
        if (steriNumber.indexOf("/") !== -1) {
            steriNumber = steriNumber.split("/")[0];
        }

        this.state = {
            resetPassword: "",
            newPassword: "",
            open: false,
            message: "",
            mock: this.props.mock,
            steriNumber
        };
    }

    componentDidMount() {
        console.log(this.state);
        console.log(this.props);
        axios.get(Object.values(this.props.steriLoggers)[0] + "/email"
            ).then(response => {
                if (response.status === 200) {
                    let email = response.data;
                    axios.get(LAMBDA_URL + "?email=" + email + "&code=" + this.state.steriNumber).then(response => {
                        this.setState({
                            message: "Please check your email for the reset password.",
                            open: true
                            });                                                
                    });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    setNewPassword = (resetPassword, newPassword)  => {
        axios.get(Object.values(this.props.steriLoggers)[0] + "/updatepw/" + resetPassword + "/" + newPassword
            ).then(response => {
            if (response.status === 200) {
                this.setState({
                    message: "Success!",
                    open: true
                    });
            }
        }).catch(error => {
            console.log(error);
        });        
    }

    updatePW = () => {
        // PW requirements validation (copied from Settings.js)
        if (this.state.newPassword.length !== 5) {
            this.setState({ 
                message: "Password must contain exactly five digits.",
                open: true
                });
            return;            
            }
        if (!this.state.newPassword.match(/^[0-9]*$/)) {
            this.setState({ 
                message: "Password must only contain numbers.",
                open: true
            });
            return;            
        } 
        if (this.state.newPassword === "99999" || this.state.newPassword === "99998") {
            this.setState({ 
                message: "This password is reserved, please choose another.",
                open: true
            });
            return;            
        }
        
        axios.get(Object.values(this.props.steriLoggers)[0] + "/resetpw/" + this.state.steriNumber + "/" + this.state.resetPassword
            ).then(response => {
                if (response.status === 200) {
                    this.setNewPassword(this.state.resetPassword, this.state.newPassword)
                }
            }).catch(error => {
                console.log(error);
            });

    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
            <Paper className={classes.settings}>
                <TextField
                    label="Reset Password"
                    type="password"
                    name="resetPassword"
                    value={this.state.resetPassword}
                    onChange={this.handleChange}
                    className={classes.textField}
                    margin="normal"
                />
                <TextField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    className={classes.textField}
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    margin="normal"
                />

                <Button variant="contained" className={classes.button} onClick={this.updatePW}>
                    <SaveIcon className={classes.leftIcon} />
                    Update Password
                </Button>
                <RedSnackbar
                open={this.state.open}
                message={this.state.message}
                onClose={this.handleClose}
            />
                        </Paper>
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ForgotPassword));