// take strip SteriA, SteriB suffixes off dual units.
function stripSuffixes(entries) {
    let steriLoggersStrippedSuffix = {};
      for (const [key, value] of entries) {
          let suffix = value.indexOf("Steri");
          // Strip off dual unit suffixes.
          if (suffix !== -1) {
              value = value.substring(0, suffix - 1);
          }
          steriLoggersStrippedSuffix[key] = value;
      }
  
    return steriLoggersStrippedSuffix;
  }

export default stripSuffixes