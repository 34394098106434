import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { withRouter } from "react-router-dom";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import axios from 'axios';

const styles = theme => ({
    root: {
        width: '100%'
    },
    settings: {
        overflowX: 'auto',
        padding: '20px'
    },
    textField: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
        marginTop: '0px',
        width: '300px',
        display: 'flex'
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 1
    },
    button: {
        display: 'flex',
        margin: '10px'
    }
});
class Test extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ip: "",
            payload: null
        };
    }

    componentDidMount() {
    }

    handleChange = e => 
        this.setState({ [e.target.name]: e.target.value });

     handleUpload = e => {
         this.setState({ [e.target.name]: e.target.files[0] })
    };

    setNewPassword = (resetPassword, newPassword)  => {
        axios.get(Object.values(this.props.steriLoggers)[0] + "/updatepw/" + resetPassword + "/" + newPassword
            ).then(response => {
            if (response.status === 200) {
                this.setState({
                    message: "Success!",
                    open: true
                    });
            }
        }).catch(error => {
            console.log(error);
        });        
    }

    test = () => {
        
                    // console.log(response);
                    const config = {
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded'
                        }
                      };
                    let formData = new FormData();
                    formData.append("payload", this.state.payload);
                    console.log(formData);
                    axios.post("http://" + this.state.ip, formData, config);
                };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
            <Paper className={classes.settings}>
                <TextField
                    label="Steri-Scan IP"
                    type="text"
                    name="ip"
                    value={this.state.ip}
                    onChange={this.handleChange}
                    className={classes.textField}
                    margin="normal"
                />

                <Button
                    variant="contained"
                    component="label"
                    >
                    {this.state.payload &&
                    <CheckCircleOutlineIcon />
                    }
                    Upload File
                    <input
                        type="file"
                        name="payload"
                        onChange={this.handleUpload}
                        hidden
                    />
                </Button>

                <Button variant="contained" className={classes.button} onClick={this.test}>
                    <SaveIcon className={classes.leftIcon} />
                    Test
                </Button>
                        </Paper>
            </div>
        );
    }
}

Test.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Test));