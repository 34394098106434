import React, { Component } from 'react';
import Dashboard from "./components/Dashboard";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

import './App.css';

class App extends Component {

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div>
                <Dashboard location={this.props.location} />                
            </div>
    </MuiPickersUtilsProvider>
        );
    }
}

export default App;
