import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from "./Loading";
import EditableTable from './EditableTable';
import BiologicalIndicators from './BiologicalIndicators';
import Password from "./Password"
import axios from 'axios';
import ls from 'local-storage';

// Note: This page is meant to be a replacement for the already existing CycleListing.js page. Refactor someday.
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  }
});

const mockUsers = `
{"Users data": [
    {"User":"Judy%20S.","PassWord":123},
    {"User":"Sally%20M.","PassWord":222},
    {"User":"Jackie","PassWord":121},
    {"User":"none","PassWord":0},
    {"User":"none","PassWord":0},
    {"User":"none","PassWord":0},
    {"User":"none","PassWord":0},
    {"User":"none","PassWord":0},
    {"User":"none","PassWord":0}]
    }`;

const mockBIs = `{"BI data": [{"Lot Num":786633,"Start time":1562079830,"Verify time":1562081421,"Cycle num":1828,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Nancy","Verify User":"Nancy","Sterilizer ID":1},
{"Lot Num":786633,"Start time":1562081014,"Verify time":1562081525,"Cycle num":1233,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Nancy","Verify User":"Teresa","Sterilizer ID":1},
{"Lot Num":786633,"Start time":1562081426,"Verify time":1562081570,"Cycle num":1233,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Krista","Verify User":"Nancy","Sterilizer ID":1},
{"Lot Num":786633,"Start time":1562081563,"Verify time":1562341028,"Cycle num":1233,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Teresa","Verify User":"Krista","Sterilizer ID":1},
{"Lot Num":786633,"Start time":1562085438,"Verify time":1562341051,"Cycle num":1233,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Krista","Verify User":"Nancy","Sterilizer ID":1},
{"Lot Num":7852,"Start time":1562342420,"Verify time":1562342444,"Cycle num":0,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Nancy","Verify User":"Krista","Sterilizer ID":1},
{"Lot Num":7852,"Start time":1562342449,"Verify time":1562342822,"Cycle num":0,"Control Vial":"Pass","Test Vial":"Pass","Start User":"Nancy","Verify User":"Krista","Sterilizer ID":1}]
}`;

class SteriloggerList extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            content: [],
            expanded: null,
            responseStatus: "",
            password: "",
            mock: this.props.mock,
            loadingCycles: false
        };

        this.passwordHandler = this.passwordHandler.bind(this)
        this.contentHandler = this.contentHandler.bind(this)
    }

  handleChange = (panel, steriloggerUri) => (event, expanded) => {
    if (panel !== this.state.expanded) {
      if (this.props.type === "bi") {
          this.getBiologicalIndicators(steriloggerUri);
      } else if (this.props.type === "contents") {
          this.getContent(steriloggerUri);
      } else if (this.props.type === "users") {
          this.getUsers(steriloggerUri)
      }
    }
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  getUsers(steriloggerUri) {
    this.setState({
        loadingCycles: true
      });

      if (this.props.mock) {
          let rawRows = JSON.parse(mockUsers)["Users data"];
          let realRows = [];
          for (let idx in rawRows) {
              if (rawRows[idx].User !== "none") {
                  rawRows[idx].User = decodeURIComponent(rawRows[idx].User);
                  rawRows[idx].id = idx;
                  realRows.push(rawRows[idx]);
              }
          }
          this.setState({
            content: realRows,
            loadingCycles: false
        });

      } else {
          axios.get(steriloggerUri + "/listuser"
          ).then(response => {
              if (response.status === 200) {
                  let rawRows = response.data["Users data"];
                  let realRows = [];
                  for (let idx in rawRows) {
                      if (rawRows[idx].User !== "none") {
                          rawRows[idx].User = decodeURIComponent(rawRows[idx].User);
                          rawRows[idx].id = idx;
                          realRows.push(rawRows[idx]);
                      }
                  }
                  this.setState({
                      content: realRows,
                      loadingCycles: false,
                      responseStatus: response.status
                  });
              }
          }).catch(error => {
              console.log(error);
              this.setState({
                loadingCycles: false,
                  responseStatus: error.response.status
              });
          });
      }
  }

  getContent(steriloggerUri) {
    this.setState({
        loadingCycles: true
      });
      if (this.state.mock) {
          let rows = [];
        //   if (ls.get("contents")) {
            //   rows = ls.get("contents");
        //   }
        //    else {
              rows = [
                  {id: 0, Item: "Exam"},
                  {id: 1, Item: "Hygiene"},
                  {id: 2, Item: "Resto"},
                  {id: 3, Item: "Surgical"},
                  {id: 4, Item: "Endo"},
                  {id: 5, Item: "CrownBdg"},
                  {id: 6, Item: "Hand Piece"},
                  {id: 7, Item: "Mixed"},
                  {id: 8, Item: "Burrs"},
                  {id: 9, Item: "Implant"},
                  {id: 10, Item: "Syringes"},
                  {id: 11, Item: "Scalers"},
                  {id: 12, Item: "Bib Clips"},
                  {id: 13, Item: "X-Holder"},
                  {id: 14, Item: "Cavitron"},
              ]
        //   }
          this.setState({
              loadingCycles: false,
              content: rows,
            });
      } else {
          axios.get(steriloggerUri + "/content"
          ).then(response => {
              if (response.status === 200) {
                  let rawRows = response.data["Contents data"];
                  let realRows = [];
                  for (let idx in rawRows) {
                      if (rawRows[idx].Item !== "none") {
                          rawRows[idx].id = idx;
                          realRows.push(rawRows[idx]);
                      }
                  }
                  this.setState({
                      loadingCycles: false,
                      content: realRows,
                      responseStatus: response.status
                  });
              }
          }).catch(error => {
              console.log(error);
              this.setState({
                  loadingCycles: false,
                  responseStatus: error.response.status
              });
          });
      }
  }

    getBiologicalIndicators(steriloggerUri) {
        this.setState({
            loadingCycles: true
          });

        // mock!!!
        // currently this will never get hit, need to pass props.
        if (this.props.mock) {
          this.setState({ content: JSON.parse(mockBIs)["BI data"],
          loadingCycles: false });
        } else {
          axios.get(steriloggerUri + "/BI"
          ).then(response => {
            if (response.status === 200) {
                let contents = response.data["BI data"];

                this.setState({
                    loadingCycles: false,
                    content: contents,
                    responseStatus: response.status
                });
            }
          }).catch(error => {
            console.log(error);
              this.setState({
                  loadingCycles: false,
                  responseStatus: error.response.status
              });
          });
        }
    }

    passwordHandler = password => {
        this.setState({
            password
        })
    };
  
    contentHandler = content => {
        this.setState({
            content
        })
    };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
      {this.props.isLoading ? 
      (
      <Loading />
    ) : (
      (this.props.mock ? !Object.entries(this.props.steriLoggers).length === 0 : Object.entries(this.props.steriLoggers).length === 0) ?
      (
        <h3 style={{textAlign: 'center'}}>No Steri-Scanners found.</h3>
      ) : (this.props.type !== "bi" && !this.state.password.length ? (
          <Password {...this.props} steriLoggers={this.props.steriLoggers} handler={this.passwordHandler} mock={this.state.mock} />
          ) : (
        (Object.keys(this.props.steriLoggers)).map((value, index) => (
          <ExpansionPanel expanded={expanded === 'panel' + index} onChange={this.handleChange('panel' + index, this.props.steriLoggers[value])}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Steri-Scan {ls.get("sterinames") && ls.get("sterinames")[value] && value.split("/")[0] + "/" + ls.get("sterinames")[value] || value}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          {this.state.loadingCycles ? (
              <div style={{textAlign: "center", width: "100%"}} >
                        <Loading />
                        </div>
                      ) : (
          this.state.responseStatus === 503 ? (
              <h3 style={{width: "100%", textAlign: 'center'}}>System currently
                  in use.</h3>
          ) : (
              <div style={{width: "100%"}}>
                  {this.props.type !== "bi" &&
                  <EditableTable type={this.props.type} mock={this.props.mock} password={this.state.password} content={this.state.content} handler={this.contentHandler}
                                 steriLoggerUri={this.props.steriLoggers[value]}/>
                  }
                  {this.props.type === "bi" &&
                  <BiologicalIndicators content={this.state.content}/>
                  }
              </div>
          ))}
          </ExpansionPanelDetails>
          </ExpansionPanel>
          )
      ))
      ))}

      </div>
    );
  }
}

SteriloggerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SteriloggerList);