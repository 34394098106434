import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        background: "#d32f2f"
    }
};

class RedSnackbar extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                ContentProps={{
                    classes: {
                        root: classes.root
                    }
                }}
                message={this.props.message}
                style={{marginBottom: "20px"}}
                autoHideDuration="3000"
                open={this.props.open}
                onClose={this.props.onClose}
            />
        );
    }
}

export default withStyles(styles)(RedSnackbar);