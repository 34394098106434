import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Loading from "./Loading";
import {
    SearchState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
    Table
} from '@devexpress/dx-react-grid-material-ui';
import axios from 'axios';
import ls from 'local-storage';

const mockBarcodeNotFound = `{"barcode":10000,"result":"not found"}`;
const mockBarcode = `{"barcode":20000,"cycle":2440,"date":"5 Jul 2019","user":"Teresa","contents":"Abe Ret","steriscan":"Steri-Scan 704/Midmark1"}`;

// function createData(barcode, cycle, date, content, user ) {
//   return { "barcode": barcode, "cycle": cycle, "date": date, "content": content, "user": user };
// }

const CustomSearchInput = ({ value, onValueChange, onCustomAction }) => {
    const [searchValue, setSearchValue] = useState(value);
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        onValueChange(searchValue);
        onCustomAction(searchValue);
      }
    };
  
    const handleChange = (newValue) => {
      setSearchValue(newValue);
    };

    const handleSearchClick = () => {
        onValueChange(searchValue);
        onCustomAction(searchValue);
      };

      const inputStyles = {
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: '1.42857143',
        color: '#555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      };      
  
    return (
    <div className="search-input-container">
      <input
        type="text"
        style={inputStyles}
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Search..."
      />
      <IconButton color="inherit" onClick={handleSearchClick}>
      <SearchIcon />
      </IconButton> 
    </div>
    );
  };

class BarcodeSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        // might only be needed for mock.
        this.changeSearchValue = this.changeSearchValue.bind(this);

        let lookup = {};
            for (const [k, v] of Object.entries(this.props.steriLoggers)) {
                lookup[v] = k.split("/")[0];
            }

        this.state = {
            mock: this.props.mock,
            loading: false,
            columns: [
                { name: 'barcode', title: 'Barcode #' },
                { name: 'cycle', title: 'Cycle #' },
                { name: 'date', title: 'Date' },
                { name: 'contents', title: 'Content' },
                { name: 'user', title: 'User' },
                { name: 'steriscan', title: 'Steri-Scan' },
            ],
            rows: [],
            lookup
        };
    }

    changeSearchValue(value) {

        this.setState({
            rows: []
        });

        let steriLoggers = this.props.steriLoggers;

        if (Object.values(steriLoggers).length > 0 || this.state.mock) {

            if (value.length >= 5) {
                    this.setState({
                        loading: true
                    });
            } if (value.length < 5) {
                    this.setState({
                        loading: false
                    });
            }

            if (value.length >= 5) {
                // this won't work yet.
                if (this.state.mock) {
                    if (value === "10000") {
                        let barcode = JSON.parse(mockBarcodeNotFound);
                        if (barcode.result === "not found") {
                            barcode = [];
                        }
                        this.setState({ 
                            rows: barcode,
                            loading: false});
                    } else {
                        this.setState({ 
                            rows: [JSON.parse(mockBarcode)],
                            loading: false
                        });
                    }
                } else {
                    let callbackCounter = 0;
                    let responses = {};
                    for (let steriLoggerUri of Object.values(steriLoggers)) {
                        axios.get(steriLoggerUri + "/barcode/" + value
                        ).then(response => {
                            let barcode = response.data;
                            if (barcode.result === "not found") {
                                barcode = {};
                            } else {
                                const number = barcode["steriscan"];
                                const steriId = this.state.lookup[steriLoggerUri];
                                const lookup2 = {"1": "SteriA", "2": "SteriB"};
                                const steriName = steriId + "/" + lookup2[number];
                                const alias = ls.get("sterinames") && ls.get("sterinames")[steriName] && steriId + "/" + ls.get("sterinames")[steriName];
                                if (alias) {
                                    barcode["steriscan"] = alias;
                                }
                            }
                            responses[steriLoggerUri] = barcode;
                        }).catch(error => {
                            console.log(error);
                            this.setState({ 
                                loading: false
                            });
                        }).then(() => {
                            callbackCounter++;
                            if (callbackCounter === Object.values(steriLoggers).length) {
                                for (let response of Object.values(responses)) {
                                    if (Object.values(response).length > 0) {
                                        this.setState({ 
                                            rows: [response]
                                        });
                                    }
                                    this.setState({
                                        loading: false
                                    })
                                }
                            }
                        });
                    }
                }
            }                
        }
    }

    render() {

        return (
            <Paper>
                <Grid
                    rows={this.state.rows}
                    columns={this.state.columns}
                >
                     <SearchState />
                            <IntegratedFiltering />
          <Table />
                    <TableHeaderRow />
                    <Toolbar />
                    <SearchPanel 
                    inputComponent={(props) => (
                        <CustomSearchInput
                          {...props}
                          onCustomAction={this.changeSearchValue}
                        />
                      )}
                    />
                </Grid>
                {this.state.loading && <Loading />}
            </Paper>
        );
    }
}

export default BarcodeSearch;